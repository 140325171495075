<script lang="ts">
  import { portal } from './Portal.svelte'

  export let title = ''
  let isHovered = false
  let x = 0
  let y = 0

  function mouseOver(event: MouseEvent) {
    isHovered = true
    x = event.clientX + 4
    y = event.clientY + 8
  }
  function mouseMove(event) {
    x = event.clientX + 4
    y = event.clientY + 8
  }
  function mouseLeave() {
    isHovered = false
  }
</script>

<div on:focus on:mouseover={mouseOver} on:mouseleave={mouseLeave} on:mousemove={mouseMove}>
  <slot />
</div>

{#if isHovered}
  <div
    use:portal={'body'}
    style="top: {y}px; left: {x}px;"
    class="fixed z-50 py-1 px-2 text-left leading-snug text-white bg-gray-900 rounded shadow-md lg:text-sm text-xs max-w-sm"
  >
    {title?.trim()}
  </div>
{/if}
